<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="TitleHatchCove"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    @update:show="toggle(false)"
    class="modal-extended"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <CCol sm="12">
              <CRow class="mt-2" align-horizontal="center">
                <CCol sm="7">
                  <CInput
                    v-model.trim="$v.tapa.HatchCoverName.$model"
                    :is-valid="hasError($v.tapa.HatchCoverName)"
                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-7'}"
                    size="sm"
                    v-uppercase
                    :label="$t('label.ID')"
                    addLabelClasses="text-right required"
                    maxlength="100"
                  >
                  </CInput>
                </CCol>
                <CCol sm="5">
                  <div class="position-relative">
                    <div class="form-group form-row" rol="group">
                      <label class="required text-right col-form-label col-sm-4 col-form-label-sm" for="localizacion">{{$t('label.localizacion')}}</label>
                      <div class="col-sm-8 input-group">
                        <v-select
                          :options="localizacionOptions"
                          placeholder="Seleccione"
                          v-model="localizacion"
                          :disabled="isLocalEmpty"
                          :class="computedSelectLocalizacion 
                            ? 'select-client--correct' 
                            : (verifylocalizacion ? 'select-client--error' : '') 
                          "
                          style="width: 100% !important;"
                          @change="Verifylocalizacion"
                        />
                        <div :class="isLocalizacionValid">
                          {{$t('label.required')}}
                        </div>
                      </div>
                    </div>
                    <CElementCover
                      :opacity="1"
                      v-show="isLoadingLocalizacion"
                    >
                      <label class="d-inline">{{$t('label.load')}}... </label>
                      <CSpinner size="sm" />
                    </CElementCover>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="7">
                  <div class="position-relative">
                    <div class="form-group form-row" rol="group">
                      <label class="required text-right col-form-label col-sm-3 col-form-label-sm" for="tpCover">{{$t('label.type')}}</label>
                      <div class="col-sm-8 input-group">
                        <v-select
                          :options="tipoOptions"
                          placeholder="Seleccione"
                          v-model="tpCover"
                          :disabled="isTipoEmpty"
                          :class="computedSelectTipo 
                            ? 'select-client--correct' 
                            : (verifytpCover ? 'select-client--error' : '') 
                          "
                          style="width: 87% !important;"
                          @change="VerifytpCover"
                        />
                        <div class="input-group-append">
                          <div class="d-flex align-items-start">
                            <CButton
                              v-if="!showCollapse"
                              shape="square"
                              color="add"
                              size="sm"
                              class="d-flex align-items-center"
                              v-c-tooltip="{
                                content: $t('label.nuevo')+''+$t('label.hatchCoverType'),
                                placement: 'top-end'
                              }"
                              @click.stop="handleCollapse()"
                            >
                              <CIcon name="cil-playlist-add"/>
                            </CButton>
                            <CButton
                              v-if="showCollapse"
                              shape="square"
                              color="add"
                              size="sm"
                              class="d-flex align-items-center"
                              v-c-tooltip="{
                                content: $t('label.nuevo')+''+$t('label.hatchCoverType'),
                                placement: 'top-end'
                              }"
                              @click.stop="handleClose"
                            >
                              <CIcon name="cil-playlist-add"/>
                            </CButton>
                          </div>
                        </div>
                        <div :class="isTipoValid">
                          {{$t('label.required')}}
                        </div>
                      </div>
                    </div>
                    <CElementCover
                      :opacity="1"
                      v-show="isLoadingTpCover"
                    >
                      <label class="d-inline">{{$t('label.load')}}... </label>
                      <CSpinner size="sm" />
                    </CElementCover>
                  </div>
                </CCol>
                <CCol sm="5">
                  <CCollapse :show="edit">
                    <CSelect
                      :value.sync="tapa.Status"
                      :is-valid="selectColor"
                      size="sm"
                      :label="$t('label.status')"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      :options="statusOptions"
                      addLabelClasses="text-right"
                      class="mt-1"
                    />
                  </CCollapse>
                </CCol>
                <CRow class="d-flex align-items-center justify-content-center col-sm-12">
                  <CCol sm="12">
                    <CCollapse :show="showCollapse">
                      <tp-hatch-cover-collapse
                        :showCollapse="showCollapse"
                        @set-Type-list="getTipos"
                      />
                    </CCollapse>
                  </CCol>
                </CRow>
              </CRow>
              <CRow v-if="!showCollapse">
                <CCol sm="12">
                  <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedTapasBodegas"
                    :fields="fields"
                    :loading="isLoading"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    :active-page="activePage"
                    pagination
                     
                  >
                    <template #loading>
                      <loading/>
                    </template>
                    <template #RowNumber="{ item }">
                      <td class="center-cell text-center">
                        <span>{{ item.RowNumber }}</span>
                      </td>
                    </template>
                    <template #HatchCoverName="{ item }">
                      <td class="center-cell text-left">
                        <span>{{ item.HatchCoverName }}</span>
                      </td>
                    </template>
                    <template #VesselHoldName="{ item }">
                      <td class="center-cell text-left">
                        <span>{{ item.VesselHoldName }}</span>
                      </td>
                    </template>
                    <template #HatchCoverLocName="{ item }">
                      <td class="center-cell text-left">
                        <span>{{ item.HatchCoverLocName }}</span>
                      </td>
                    </template>
                    <template #TpHatchCoverName="{ item }">
                      <td class="center-cell text-left">
                        <span>{{ item.TpHatchCoverName }}</span>
                      </td>
                    </template>
                    <template #UltimoUsuario="{ item }">
                      <td class="center-cell text-left">
                        <span>{{ item.UltimoUsuario }}</span>
                      </td>
                    </template>
                    <template #FormatedDate="{ item }">
                      <td class="text-center align-middle">
                        <span>{{ item.FormatedDate }}</span>
                      </td>
                    </template>

                    <template #Status="{item}">
                      <td class="text-center align-middle">
                        <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                        </CBadge>
                      </td>
                    </template>

                    <template #Details="{ item }">
                      <td class="text-center align-middle">
                          <CButton
                            color="edit"
                            size="sm"
                            class="mr-2"
                            v-c-tooltip="{
                              content: $t('label.edit')+' '+$t('label.hatchCover'),
                              placement: 'top-end'
                            }"
                            @click.stop="handleEdit(item)"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>

        </CCol>
      </CRow>
    </form>
    <template #footer>
      <CButton
        shape="square"
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmitInvalid"
        @click.stop="statusConfirmation"
      >
        <div v-if="!isSubmit">
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </div>
        <div v-if="isSubmit">
          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </div>
      </CButton>
      <CButton
        shape="square"
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import HatchCoverFormValidations from '@/_validations/buque/HatchCoverFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';

import TpHatchCoverCollapse from './tipo-tapa-collapse';


function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%; text-align:center;',
      filter:false,
    },
    { key: 'HatchCoverName', label: this.$t('label.hatchCover'), _style: 'width:17%;'},
    { key: 'VesselHoldName', label: this.$t('label.hold'), _style: 'width:15%;' },
    { key: 'HatchCoverLocName', label: this.$t('label.localizacion'), _style: 'width:15%;' },
    { key: 'TpHatchCoverName', label: this.$t('label.type'), _style: 'width:15%;'},
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'text-align:center; width:10%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'text-align:center; width:10%;', _classes:'text-center'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
    }
  ];
}

//Created:
function created() { }

//Data:
function data() {
  return {
    isSubmit: false,
    isLoading: false,
    isLoadingGeneral: false,
    modalActive: false,
    items: [],
    bayHold: {
      VesselId: '',
      VesselName: '',
      Imo: '',
      VesselHoldId: '',
      VesselHoldName: ''
    },
    tapa: {
      VesselHoldHatchCId: '',
      HatchCoverName: '',
      HatchCoverLocId: '',
      TpHatchCoverId: '',
      Status: 1
    },
    actHacth: false,
    edit: false,
    activePage: 1,
    isLoadingLocalizacion: false,
    localizacion: null,
    locals: [],
    isLoadingTpCover: false,
    tpCover: null,
    tipos: [],
    showCollapse: false,
    verifylocalizacion: false,
    verifytpCover: false,
  }
}

//Methods:
function loadData(idBuque, idHold) {
  this.isLoadingGeneral = true;
  let hatchListParams = {
    VesselId: idBuque,
    VesselHoldId: idHold,
    Filter: 'ALL'
  };

  let peticiones = [
    this.$http.ejecutar('GET', 'VesselHoldHatchCover-list', hatchListParams),
    this.$http.ejecutar('GET', 'HatchCoverLocation-list', { Filter: 'ACTIVO'}),
    this.$http.ejecutar('GET', 'TpHatchCover-list', { Filter: 'ACTIVO' })
  ];

  Promise.all(peticiones)
  .then((responses) => {
    this.items = responses[0].data.data;
    this.locals = responses[1].data.data;
    this.tipos = responses[2].data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}
function getTapasBodegas(idBuque, idHold) {  
  this.isLoading = true;

  let params = {
    VesselId: idBuque,
    VesselHoldId: idHold
  }

  this.$http.ejecutar('GET', 'VesselHoldHatchCover-list', params)
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function getLocals() {  
  this.isLoadingLocalizacion = true;

  this.$http.ejecutar('GET', 'HatchCoverLocation-list', { Filter: 'ACTIVO' })
  .then(response => {
    this.locals = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingLocalizacion = false;
  });
}
async function getTipos() {  
  this.isLoadingTpCover = true;

  this.$http.ejecutar('GET', 'TpHatchCover-list', { Filter: 'ACTIVO' })
  .then(response => {
    this.tipos = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpCover = false;
  });
}
function toggle(val) {
  if(val)
    this.loadData(this.hold.VesselId, this.hold.VesselHoldId);
  else
    this.resetInputs();
    this.verifytpCover = false;
    this.verifylocalizacion = false;
    this.showCollapse = false;
    this.modalActive = val;
}

function setData(newData) {
  this.bayHold.VesselId = newData.VesselId;
  this.bayHold.VesselName = newData.VesselName;
  this.bayHold.Imo = newData.Imo;
  this.bayHold.VesselHoldId = newData.VesselHoldId;
  this.bayHold.VesselHoldName = newData.VesselHoldName;
}
function submit() {
  try {
    this.$v.$touch();
    this.verifylocalizacion = true;
    this.verifytpCover = true;
    if (this.$v.$error) {
        this.isLoadingGeneral = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
    }
    this.isSubmit = true;
    this.isLoadingGeneral = true;
    let metodo = this.edit?'PUT':'POST';
    let ruta = this.edit?'VesselHoldHatchCover-update':'VesselHoldHatchCover-insert';
    let VesselHoldHatchCoverJson = {...this.formatedData()};

    this.$http.ejecutar(metodo, ruta, VesselHoldHatchCoverJson, { 
      root: 'VesselHoldHatchCoverJson'
    }).then(response => {
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetInputs();
      this.getTapasBodegas(this.hold.VesselId, this.hold.VesselHoldId);
      this.isSubmit = false;
      this.isLoadingGeneral = false;
    }).catch(err => {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
  } catch (e) {
    this.isSubmit = false;
    this.isLoadingGeneral = false;
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }  
}
function resetInputs() {
  if(!this.edit){
    this.tapa.HatchCoverName = '';
    this.tpCover = null;
    this.localizacion = null;
    this.$v.$reset();
  } else{
    this.tapa.VesselHoldHatchCId = '';
    this.tapa.HatchCoverName = '';
    this.tpCover = null;
    this.localizacion = null;
    this.tapa.Status = 1;
    this.actHacth = false;
    this.edit = false;
    this.$v.$reset();
  }
}
function formatedData() {
  let HatchCover = {
    VesselHoldId: this.hold.VesselHoldId,
    HatchCoverName: this.tapa.HatchCoverName,
    TpHatchCoverId: this.tpCover.TpHatchCoverId,
    HatchCoverLocId: this.localizacion.HatchCoverLocId
  }
  if(!this.edit){
    return {...HatchCover};
  } else {
    return {
      ...HatchCover,
      VesselHoldHatchCId: this.tapa.VesselHoldHatchCId,
      Status: this.tapa.Status
    };
  }
}
function handleEdit(item) {
  this.tapa.VesselHoldHatchCId = item.VesselHoldHatchCId;
  this.tapa.HatchCoverName = item.HatchCoverName;
  this.tapa.TpHatchCoverId = item.TpHatchCoverId;
  this.tapa.HatchCoverLocId = item.HatchCoverLocId;
  this.tapa.Status = item.FgActVesselHoldHatchC?1:0;
  this.actHacth = item.FgActVesselHoldHatchC;

  this.localizacion = Object.assign({}, {
    HatchCoverLocId: item.HatchCoverLocId,
    HatchCoverLocName: item.HatchCoverLocName,
    label: item.HatchCoverLocName,
    value: item.HatchCoverLocId
  });
  this.tpCover = Object.assign({}, {
    TpHatchCoverId: item.TpHatchCoverId,
    TpHatchCoverName: item.TpHatchCoverName,
    label: item.TpHatchCoverName,
    value: item.TpHatchCoverId
  });
  this.verifylocalizacion = true;
  this.verifytpCover = true;
  this.edit = true;
  this.$v.$touch();
}
function statusConfirmation(){
  if(!this.edit)
    this.submit();
  else{
    let status = this.actHacth?1:0;
    if(this.tapa.Status === status)
      this.submit();
    else{
      this.$swal.fire({
        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.tapa.HatchCoverName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }
}
function Verifylocalizacion() {
  this.verifylocalizacion = true;
}
function VerifytpCover() {
  this.verifytpCover = true;
}
function handleCollapse() {
  this.showCollapse = true;
}
function handleClose() {
  this.showCollapse = false;
  this.tpCover = null;
}

//Computerd: 
function buqueIMO() {
  return this.hold && this.hold.VesselName && this.hold.Imo
    ?`${this.hold.VesselName} - ${this.hold.Imo}`
    :'';
}
function TitleHatchCove() {
  if (!this.edit) {
    return this.$t('label.nueva')+' '+this.$t('label.HoldHatchCover')+' '+this.bayHold.VesselHoldName;
  } else {
    return this.$t('label.edit')+' '+this.$t('label.HoldHatchCover')+' '+this.bayHold.VesselHoldName+': '+this.tapa.HatchCoverName;
  }
}
function formatedTapasBodegas() {
  let _lang = this.$i18n.locale;
  return this.items.map((item) => Object.assign({}, item, {
    HatchCoverLocName: _lang=='en' ? item.HatchCoverLocNameEn : item.HatchCoverLocNameEs,
    TpHatchCoverName: _lang=='en' ? item.TpHatchCoverNameEn : item.TpHatchCoverNameEs,
    RowNumber: item.Nro,
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function isSubmitInvalid(){
  return this.isSubmit;
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function selectColor(){
  return this.tapa.Status === 1;
}
function localizacionOptions() {
  let _lang = this.$i18n.locale;
  return this.locals.map((item) => Object.assign({}, item, {
    value: item.HatchCoverLocId,
    label: _lang=='en' ? item.HatchCoverLocNameEn : item.HatchCoverLocNameEs
  }));
}
function isLocalEmpty() {
  return this.locals.length == 0;
}
function isLocalizacionValid() {
  return {
    'invalid-feedback': true,
    invalid: this.localizacion? null : this.$v.$anyDirty? this.$v.localizacion.$invalid : null
  };
}
function tipoOptions() {
  let _lang = this.$i18n.locale;
  return this.tipos.map((item) => Object.assign({}, item, {
    value: item.TpHatchCoverId,
    label: _lang=='en' ? item.TpHatchCoverNameEn : item.TpHatchCoverNameEs
  }));
}
function isTipoEmpty() {
  return this.tipos.length == 0;
}
function isTipoValid() {
  return {
    'invalid-feedback': true,
    invalid: this.tpCover? null : this.$v.$anyDirty? this.$v.tpCover.$invalid : null
  };
}
function computedSelectLocalizacion() {
  if (this.localizacion === '' || this.localizacion === null) {
    return false;
  } else {
    return true;
  }
}
function computedSelectTipo() {
  if (this.tpCover === '' || this.tpCover === null) {
    return false;
  } else {
    return true;
  }
}

export default {
  name: 'tapas-hold-modal',
  mixins: [GeneralMixin],
  props: {
    modal: Boolean,
    hold: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    TpHatchCoverCollapse
  },
  data,
  validations: HatchCoverFormValidations,
  directives: UpperCase,
  methods: {
    loadData,
    getTapasBodegas,
    setData,
    toggle,
    getLocals,
    getTipos,
    handleCollapse,
    Verifylocalizacion,
    VerifytpCover,
    submit,
    resetInputs,
    statusConfirmation,
    handleEdit,
    formatedData,
    handleClose
  },
  computed: {
    TitleHatchCove,
    buqueIMO,
    formatedTapasBodegas,
    localizacionOptions,
    isLocalEmpty,
    isLocalizacionValid,
    tipoOptions,
    isTipoEmpty,
    isTipoValid,
    isSubmitInvalid,
    statusOptions,
    selectColor,
    computedSelectLocalizacion,
    computedSelectTipo,
    fields,
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    hold: function(newBay) {
      if( Object.keys(newBay).length != 0 && newBay.VesselHoldId) {
        this.setData(this.hold);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid {
  display: block;
}
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}
.center-cell {
  text-align: center;
}
</style>
<style lang="scss">
  .select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }
  </style>