<template>
    
<CModalExtended
  :title="title"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>        
      <CCol sm="12" lg="12" class="mb-2">
        <vue-dropzone 
          ref="myVueDropzone" 
          id="dropzone" 
          :options="dropzoneOptions" 
          :useCustomSlot="true"
          v-on:vdropzone-files-added="sendingEvent"
          v-on:vdropzone-removed-file="deleteFile"
          v-on:vdropzone-error="DropzoneError"
        > 
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
            <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
          </div>
        </vue-dropzone>
      </CCol>        
      <CCol sm="12" lg="10">
        <CInput
          :label="$t('label.description')"
          :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
          maxlength="250"
          v-model.trim="$v.documento.VesselDocDesc.$model"
          addLabelClasses="text-right required"
          :is-valid="hasError($v.documento.VesselDocDesc)"
          :invalid-feedback="errorMessage($v.documento.VesselDocDesc)"
        />
      </CCol>
      <CCol sm="11" lg="2">
        <CButton color="add" square size="sm" @click="submit" :disabled="isSubmit">
            <CIcon name="checkAlt"/>
          </CButton>
      </CCol>

      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="docsOptions"
          :fields="fields"
          :loading="isLoading"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="tableText.itemsPerPage"
          hover
          small
          sorter
          pagination
        >
          <template #loading>
            <loading/>
          </template>
          <template #Nro="{item}">
            <td class="center-cell">
              {{ item.Nro }}
            </td>
          </template>
          <template #VesselDocDesc="{item}">
            <td class="center-cell text-left">
              {{ item.VesselDocDesc }}
            </td>
          </template>
          <template #TransaLogin="{item}">
            <td class="center-cell text-left">
              {{ item.TransaLogin }}
            </td>
          </template>
          <template #FormatedDate="{item}">
            <td class="center-cell-vertical">
              {{ item.FormatedDate }}
            </td>
          </template>
          <template #Status="{item}">
            <td class="center-cell-vertical text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Details="{item}">
            <td class="center-cell">
              <CButton 
                class="mr-1"
                color="excel" 
                square 
                size="sm" 
                v-c-tooltip="{
                  content: $t('label.download'),
                  placement: 'top-end'
                }"
                target='_blank' 
                :href="item.Ruta" 
              >
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButton 
                color="wipe" 
                square 
                size="sm" 
                v-c-tooltip="{
                  content: $t('label.delete'),
                  placement: 'top-end'
                }"
                @click.stop="changeStatus(item)"
                :disabled="isChange"
              >
                <CIcon name="cil-trash" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>  
  </form>
  <template #footer>
    <CButton
      shape="square"
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.exit')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import DocFormValidations from '@/_validations/buque/DocFormValidations';
import { DateFormater, tableTextHelpers } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import { DocMixin } from '@/_mixins/buque';
import  GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

let user = JSON.parse(localStorage.getItem('user'));

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:3%;text-align:center;',
      filter:false,
    },
    { key: 'VesselDocDesc', label:this.$t('label.document'), _style:'width:45%;'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%; text-align:center;'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%; text-align:center;'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width: 10%; min-width:100px', 
      sorter: false, 
      filter: false,
    }
  ];
}

//Data
function data() {
  return {
    modalActive: this.modal,
    isLoading: false,
    isSubmit: false,
    isChange: false,
    Loading: false,
    documento: {
      VesselId: '',
      VesselDocDesc: '',
      RouteDocDesc: '',
      Status: 1
    },
    fileDoc: null,
    documentos: [],
    oldDocs: [],
    uploadPercent: 0,
    responsePercent: 0,
    docFile: null,
    uploadPercent: 0,
    responsePercent: 0,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
          this.removeAllFiles();
          this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    }
  }
}

//Methods
function changeStatus(doc) {
  this.$swal
    .fire(this.alertProperties({
      text: `${this.$t('label.deleteQuestion')+' '} ${doc.VesselDocDesc}?`, 
    }))
    .then((result) => {
      if (result.isConfirmed) {
        this.isChange = true;

        let VesselDocJson = {
          VesselDocId: doc.VesselDocId,
          VesselId: doc.VesselId,
          VesselDocDesc: doc.VesselDocDesc,
          RouteDocDesc: doc.RouteDocDesc,
          Status: doc.Status == 'ACTIVO' ? 0 : 1
        };

        this.$http.ejecutar('PUT', 'VesselDoc-update ', VesselDocJson, { root: 'VesselDocJson' })
        .then(response => {
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
          this.resetForm();
          this.getDocs(this.VesselId);
        }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
        }).then(() => {
          this.isChange = false;
        });
      } else {
      // this.$swal(this.$t('label.operationCancelled'));
      }
    });
}

function getDocs(vesselId) {
  this.isLoading = true;
  this.isSubmit = true;
  this.$http.ejecutar('GET', 'VesselDoc-list', { VesselId: vesselId })
  .then(response => {
    this.oldDocs = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
    this.isSubmit = false;
  });
}

function toggle(newVal) {
  if(!newVal)
    this.resetAll();
  else {
    this.documento.VesselId = this.VesselId;
    this.getDocs(this.VesselId);
  }

  this.modalActive = newVal;
}

function resetForm() {
  this.documento.VesselDocDesc = '';
  this.documento.RouteDocDesc = '';
  this.documento.Status = 1;
  this.$refs.myVueDropzone.removeAllFiles();
  this.$v.$reset();

}

function resetAll() {
  this.documento.VesselId = '';
  this.documentos = [];
  this.resetForm();
}

function sendingEvent(files){
 // this.$refs.myVueDropzone.removeFile(files);
  if(files[0].name) this.docFile = files[0];
}

function deleteFile(file) {
  this.docFile = null;
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    const files = this.$refs.myVueDropzone.getQueuedFiles();

    if (this.$v.$invalid && files.length === 0) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(files.length === 0){
      this.Loading = false;
      this.isSubmit = false;
      this.$notify({
          group: 'container',
          type: 'error',
          ignoreDuplicates:false,
          title: 'ERROR',
          text: `${this.$t('label.documentQuestion')}`,
      });
      return;    
    }
    
    let formData = new FormData();
    formData.append('VesselId', this.documento.VesselId);
    formData.append('File', this.docFile);
    this.$http.post("VesselDoc-insert", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}files/`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this),
        onDownloadProgress: function( progressEvent ) {
          this.responsePercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((responseImage) => {
      this.documento.RouteDocDesc = responseImage.data.data.files[0].path;
      this.Loading = true;
      this.isSubmit = true;
      let res = [];
      let metodo = '';
      let ruta = '';
      let VesselDocJson = [];

      VesselDocJson = {
        VesselId: this.documento.VesselId,
        VesselDocDesc: this.documento.VesselDocDesc,
        RouteDocDesc: this.documento.RouteDocDesc,
      };
        metodo = 'POST';
        ruta = "VesselDoc-insert";
      
      this.$http.ejecutar(metodo, ruta, VesselDocJson, { root: 'VesselDocJson' })
      .then(response => {
        res = [...response.data.data];
        this.$emit('submited');
        this.resetForm();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false;
        this.isSubmit = false;
        this.getDocs(this.documento.VesselId);
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
        this.isSubmit = false;
      });
    }).catch((error) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    })
    .then(() => { 
      this.Loading = false;
      this.isSubmit = false;
    });
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}


//Computeds:
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}

function docsOptions() {
  return [...this.oldDocs, ...this.documentos].map((doc) => Object.assign({}, doc, {
    old: doc.VesselDocId?true:false,
    Ruta: doc.RouteDocDesc?`${this.$store.getters["connection/getBase"]}${doc.RouteDocDesc.replace('Public/', '', null, 'i')}`:'',
    FormatedDate: doc.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(doc.TransaRegDate) : 'N/A',
    _classes: doc.Status != 'ACTIVO'? 'text-center table-danger': 'text-center',
  }));
}

export default {
  name: 'add-doc-modal',
  mixins: [
    GeneralMixin,
    DocMixin
  ],
  props: {
    modal: Boolean,
    VesselId: {
      type: String,
      default: ''
    },
    title: String,
  },
  data,
  components: {
    vueDropzone: vue2Dropzone
  },
  validations: DocFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    changeStatus,
    getDocs,
    resetAll,
    sendingEvent,
    deleteFile,
    DropzoneError
  },
  computed: {
    statusOptions,
    docsOptions,
    fields,
    ...mapState({
        user: state => state.auth.user
    })
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    VesselId: function(newId) {
      if(newId){
        this.documento.VesselId = newId;
        this.getDocs(newId);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.center-cell-vertical {
  vertical-align: middle !important;
}

.center-cell {
  text-align: center;
}
 

.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>